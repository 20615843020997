<template>
  <div class="no-results">
    <img src="@/assets/empty-file.svg">
    <div class="has-text-black mb-3">
      {{ bannerLabel }}
    </div>
    <b-button
      type="is-primary"
      class="px-6 no-results-btn"
      @click="buttonHandler()"
    >
      <b-icon
        icon="plus"
        size="is-small"
      />{{ buttonLabel }}
    </b-button>
  </div>
</template>

<script>
export default {
    name: 'NoResults',

    props: {
        buttonLabel: {
            type: String,
            default: ''
        },
        buttonHandler: {
            type: Function,
            default: () => {}
        },
        bannerLabel: {
            type: String,
            default: ''
        }
    }
};
</script>

<style lang="scss">
.no-results {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  img {
    object-fit: cover;
    object-position: center center;
    max-width: 450px;
  }
  .no-results-btn {
  font-size: 14px;
  border-radius: 8px;
    .icon {
      margin-right: 1px !important;
    }
  }
}
</style>
