// @ts-check
import { authInterceptor, createHttpServiceConfig } from '@/services/httpFactories';
import { BUILD_SERVICE_ENDPOINT, ENVIRONMENT_RESOURCE_PATH } from './buildServiceConstants';

const environmentServiceConfig = createHttpServiceConfig(BUILD_SERVICE_ENDPOINT, ENVIRONMENT_RESOURCE_PATH);

//-- request interceptors --//
environmentServiceConfig.addRequestInterceptor(authInterceptor);

export const fetchEnvironmentsService = () => environmentServiceConfig.http({
    method: 'GET'
});
