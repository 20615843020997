<template>
  <div>
    <teleport selector="#appButton">
      <b-button
        class="px-6 add-new-btn btn-primary-light"
        @click="openNewEnvVarModal()"
      >
        <b-icon
          icon="plus"
          size="is-small"
        />New environment variable
      </b-button>
    </teleport>

    <b-table
      v-if="variables.length > 0"
      :data="variables"
      class="tab-table-layout"
      @click="openUpdateEnvVarModal($event)"
    >
      <b-table-column
        v-slot="{ row }"
        label="Name"
        field="name"
        width="40%"
      >
        {{ row.name }}
      </b-table-column>
      <b-table-column
        v-slot="{ row }"
        label="Reference"
        field="reference"
      >
        {{ row.reference }}
      </b-table-column>
      <b-table-column
        v-slot="{ row }"
        label=""
        cell-class="has-text-right"
      >
        <b-button
          size="is-small"
          class="app-dropdown-btn"
          @click.stop="toggleActionDropdown(row.reference)"
        >
          <b-icon
            icon="dots-horizontal"
            size="is-size-6"
            class="app-dropdown-icon"
          />
        </b-button>
        <b-dropdown
          :ref="`moduleActionDropdown${row.reference}`"
          aria-role="list"
          position="is-bottom-left"
          class="app-action-dropdown"
          append-to-body
        >
          <b-dropdown-item
            aria-role="listitem"
            class="is-flex is-align-items-center"
            @click="openUpdateEnvVarModal(row)"
          >
            <b-icon
              icon="pencil-outline"
              type="is-black"
              custom-size="mdi-22px"
              class="mr-2"
            />
            Edit
          </b-dropdown-item>
          <b-dropdown-item
            aria-role="listitem"
            custom
            class="is-flex is-align-items-center is-justify-content-center"
          >
            <b-button
              icon-left="delete-outline"
              size="is-small"
              type="is-danger is-light"
              class="rounded-8 w-full"
              @click.stop="deleteEnvVar(row.reference)"
            >
              Delete
            </b-button>
          </b-dropdown-item>
        </b-dropdown>
      </b-table-column>
    </b-table>

    <NoResults
      v-else-if="variables.length === 0"
      banner-label="No environment variables yet, create one now."
      button-label="New environment variable"
      :button-handler="openNewEnvVarModal"
    />

    <BaseModal
      v-model="newEnvVarModalActive"
      :has-modal-card="true"
      :trap-focus="true"
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="New module"
      aria-modal
    >
      <CardPopup
        title="New module"
        class="w-800"
        @hide="newEnvVarModalActive = false"
      >
        <template #body>
          <b-field label="Variable name">
            <b-input
              v-model="variable.name"
              size="is-small"
            />
          </b-field>
          <span class="label my-4">Value on environments</span>

          <div
            v-for="(environment, index) in environments"
            :key="index"
          >
            <b-field
              :label="environment.name"
              class="mt-4"
            >
              <b-input
                v-if="variable.environments[index]"
                v-model="variable.environments[index].value"
                size="is-small"
                expanded
              />
            </b-field>
          </div>
        </template>
        <template #footer>
          <div class="is-flex is-justify-content-space-between w-full">
            <b-button
              class="px-6 rounded-8 btn-primary-light"
              @click="newEnvVarModalActive = false;
                      updateEnvVarModalActive = false"
            >
              Cancel
            </b-button>

            <b-button
              v-if="updateEnvVarModalActive"
              type="is-primary"
              class="px-6 rounded-8"
              @click="updateEnvVar()"
            >
              Update
            </b-button>

            <b-button
              v-else-if="newEnvVarModalActive"
              type="is-primary"
              class="px-6 rounded-8"
              @click="createEnvVar()"
            >
              Create
            </b-button>
          </div>
        </template>
      </CardPopup>
    </BaseModal>
  </div>
</template>

<script >
import { fetchEnvironmentsService } from '@/services/build-service/environmentRequests';
import { computed, onMounted, ref, getCurrentInstance } from '@vue/composition-api';
import BaseModal from '@/modules/core/components/generics/base-modal/BaseModal';
import CardPopup from '@/modules/core/components/generics/base-modal/CardPopup.vue';
import { useModuleStore } from '@/modules/builder/store/moduleStore';
import { useRoute } from '@/hooks/vueRouter';
import NoResults from '@/modules/core/components/NoResults';
import { useBuefy } from '@/hooks/buefy';
import { uuid } from 'vue-uuid';
import { createEnvironmentVariableService, updateEnvironmentVariableService, deleteEnvironmentVariable, fetchEnvironmentVariablesService, fetchSingleEnvironmentVariablesService } from '@/services/application-service/environmentVariableRequest';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  onMounted(() => {
    fetchEnvironments();
    fetchEnvironmentVariables();
  });
  const buefy = useBuefy();
  const route = useRoute();
  const moduleStore = useModuleStore();
  const environments = ref([]);
  const variable = ref({
    name: '',
    reference: '',
    environments: []
  });
  const variables = ref([]);
  const vm = getCurrentInstance();
  const generateEnvVariable = payload => {
    return environments.value.map(v => {
      const macthedItem = payload.find(e => e.id === v.id);
      return {
        id: v.id,
        value: macthedItem.value
      };
    });
  };
  const fetchEnvironments = async () => {
    try {
      const {
        data: {
          data
        }
      } = await fetchEnvironmentsService();
      environments.value = data;
      const payload = environments.value.map(v => {
        return {
          id: v.id,
          value: ''
        };
      });
      variable.value.environments = generateEnvVariable(payload);
    } catch (err) {
      console.error(err);
    }
  };
  const fetchEnvironmentVariables = async () => {
    try {
      const {
        data: {
          data
        }
      } = await fetchEnvironmentVariablesService(route.params.appId);
      variables.value = data;
    } catch (err) {
      console.log(err);
    }
  };
  const newEnvVarModalActive = computed({
    get() {
      return moduleStore.isNewEnvVarModalActive;
    },
    set(value) {
      moduleStore.setEnvVarActivity(value);
    }
  });
  const updateEnvVarModalActive = computed({
    get() {
      return moduleStore.isUpdateEnvVarModalActive;
    },
    set(value) {
      moduleStore.setUpdateEnvVarActive(value);
    }
  });
  const openNewEnvVarModal = async (event = false) => {
    if (event.reference) {
      try {
        const {
          data: {
            data
          }
        } = await fetchSingleEnvironmentVariablesService(route.params.appId, {
          reference: event.reference
        });
        const {
          name,
          reference,
          environments
        } = data[0];
        variable.value = {
          name,
          reference,
          environments: generateEnvVariable(environments)
        };
      } catch (err) {
        console.log(err);
      }
    } else {
      variable.value = {
        name: '',
        reference: '',
        environments: []
      };
      await fetchEnvironments();
    }
    moduleStore.setEnvVarActivity(true);
    moduleStore.setUpdateEnvVarActive(false);
  };
  const openUpdateEnvVarModal = row => {
    moduleStore.setEnvVarActivity(true);
    moduleStore.setUpdateEnvVarActive(true);
    const {
      name,
      reference,
      environments
    } = row;
    variable.value = {
      name,
      reference,
      environments: generateEnvVariable(environments)
    };
  };
  const createEnvVar = async () => {
    try {
      moduleStore.setUpdateEnvVarActive(false);
      variable.value.reference = uuid.v4();
      await createEnvironmentVariableService(route.params.appId, {
        variable: variable.value
      });
      newEnvVarModalActive.value = false;
      await fetchEnvironmentVariables();
    } catch (err) {
      console.log(err);
    }
  };

  // Send API request to update env var
  const updateEnvVar = async () => {
    try {
      await updateEnvironmentVariableService(route.params.appId, {
        reference: variable.value.reference
      }, {
        variable: variable.value
      });
      await fetchEnvironmentVariables();
      moduleStore.setEnvVarActivity(false);
      moduleStore.setUpdateEnvVarActive(false);
    } catch (err) {
      console.log(err);
    }
  };
  const toggleActionDropdown = rowId => {
    // @VUE3: use functional template ref in vue 3 instead (check offical vue 3 docs for info), vue 2 composition api plugin doesn't support them
    vm.proxy.$refs[`moduleActionDropdown${rowId}`].toggle();
  };
  const deleteEnvVar = id => {
    buefy.dialog.confirm({
      title: 'Deleting environment variable',
      message: '<div style="padding: 1em;">Are you sure you want to <b>delete</b> this variable?</div>',
      confirmText: 'Yes',
      cancelText: 'No',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: async () => {
        try {
          await deleteEnvironmentVariable(route.params.appId, {
            reference: id
          });
          buefy.toast.open('Environment variable deleted!');
          await fetchEnvironmentVariables();
        } catch (err) {
          console.error(err);
        }
      }
    });
  };
  return {
    environments,
    variable,
    variables,
    newEnvVarModalActive,
    updateEnvVarModalActive,
    openNewEnvVarModal,
    openUpdateEnvVarModal,
    createEnvVar,
    updateEnvVar,
    toggleActionDropdown,
    deleteEnvVar
  };
};
__sfc_main.components = Object.assign({
  NoResults,
  BaseModal,
  CardPopup
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss">
@import '~@/style/variables.scss';
@import '~@/style/components.scss';
</style>