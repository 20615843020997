<template>
  <teleport
    selector="#modalsTarget"
    :disabled="!isAttachedOnBody"
  >
    <b-modal
      v-model="isActive"
      v-bind="$attrs"
      class="app-modal"
      :class="modalClass"
    >
      <slot />
    </b-modal>
  </teleport>
</template>



<script >
import { computed } from '@vue/composition-api';

//-- component props --//
const __sfc_main = {
  inheritAttrs: false
};
__sfc_main.props = {
  value: {
    type: Boolean,
    default: false
  },
  modalClass: {
    type: String,
    default: ''
  },
  isAttachedOnBody: {
    type: Boolean,
    default: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;

  //-- component events --//
  const emit = __ctx.emit;

  //-- popup logic --// 
  const isActive = computed({
    get() {
      return props.value;
    },
    set(value) {
      emit('input', value);
    }
  });
  return {
    isActive
  };
};
export default __sfc_main;
</script>

<style lang="scss">
.app-modal {
  .modal-background {
    backdrop-filter: blur(5px);
    background: transparent;
  }
  .animation-content {
    border-radius: 0.5rem;
    box-shadow: 0px 2px 10px rgba(black, 0.2);
  }
  .modal-close {
    display: none;
  }
}
</style>
